<template>
  <van-tabbar route :border="false" @change="onChange">
    <div class="max-w-2xl mx-auto flex-1 flex justify-between items-center">
      <van-tabbar-item to="/home">
        <template #icon="props">
          <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 0h7.384c.826 0 1.576.338 2.119.881l.046.051c.516.538.835 1.269.835 2.068v7.385c0 .822-.338 1.571-.881 2.116l-.005.005a2.987 2.987 0 01-2.114.879H3a2.992 2.992 0 01-2.117-.881H.88A2.99 2.99 0 010 10.385V3C0 2.177.337 1.427.88.884L.883.881A2.992 2.992 0 013 0m0 14.617h7.384a2.99 2.99 0 012.119.88l.046.049c.516.54.835 1.27.835 2.071V25a2.99 2.99 0 01-.881 2.117v.003a2.99 2.99 0 01-2.119.88H3a2.99 2.99 0 01-2.117-.88l-.003-.003A2.99 2.99 0 010 25v-7.383c0-.824.337-1.573.88-2.116l.003-.004A2.99 2.99 0 013 14.617m14.616 0h7.383a2.99 2.99 0 012.117.88l.003.004c.543.543.881 1.292.881 2.116V25a2.99 2.99 0 01-.881 2.117l-.003.003a2.99 2.99 0 01-2.117.88h-7.383a2.99 2.99 0 01-2.117-.88l-.003-.003a2.99 2.99 0 01-.88-2.117v-7.383c0-.824.337-1.573.88-2.116l.003-.004a2.99 2.99 0 012.117-.88m0-14.617h7.383c.824 0 1.573.338 2.117.881l.003.003A2.99 2.99 0 0128 3v7.385c0 .825-.338 1.577-.881 2.119l-.049.046c-.54.515-1.27.835-2.071.835h-7.383a2.992 2.992 0 01-2.117-.881h-.003a2.99 2.99 0 01-.88-2.119V3c0-.823.337-1.573.88-2.116l.003-.003A2.992 2.992 0 0117.616 0"
              fill="#FFF"
              fill-rule="evenodd"
            />
          </svg>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/mall">
        <template #icon="props">
          <svg
            width="30"
            height="28"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <defs><path id="prefix__a" d="M0 0h29.171v28H0z" /></defs>
            <g fill="none" fill-rule="evenodd">
              <mask id="prefix__b" fill="#fff">
                <use xlink:href="#prefix__a" />
              </mask>
              <path
                d="M27.983 12.92v14.282a.798.798 0 01-.799.798h-15.84a.798.798 0 01-.799-.798V16.106H5.211v11.096a.798.798 0 01-.798.798H2.167a.798.798 0 01-.799-.798V13.045l.447.308a.797.797 0 00.917.008l2.624-1.813 2.612 1.805a.797.797 0 00.917.008l2.625-1.813 2.612 1.805a.795.795 0 00.916.008l2.624-1.813 2.613 1.805a.797.797 0 00.917.008l2.624-1.813 2.613 1.805a.797.797 0 00.917.008l.637-.441zM7.71 0L6.211 7.737l.005 2.47 2.216 1.532 2.348-1.621-.007-2.417a.768.768 0 010-.096L11.249 0H7.71zM4.626 10.118L4.62 7.71a.754.754 0 01.012-.206L6.086 0H2.541L0 7.623v2.542l2.279 1.574 2.347-1.621zM12.846 0l-.482 7.697.007 2.51 2.215 1.532 2.349-1.624.006-2.418L16.483 0h-3.637zm5.233 0l.453 7.605a.768.768 0 010 .096l-.007 2.507 2.215 1.53 2.349-1.623.005-2.378L21.595 0h-3.516zm5.14 0l1.454 7.504a.755.755 0 01.013.206l-.007 2.498 2.214 1.53 2.278-1.573V7.658L26.049 0h-2.83zm-7.17 20.925h7.516v-4.44h-7.516v4.44z"
                fill="#FFF"
                mask="url(#prefix__b)"
              />
            </g>
          </svg>
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <template #icon="props">
          <svg
            width="30"
            height="28"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <defs><path id="prefix__a" d="M0 0h29.478v28H0z" /></defs>
            <g transform="translate(.17)" fill="none" fill-rule="evenodd">
              <mask id="prefix__b" fill="#fff">
                <use xlink:href="#prefix__a" />
              </mask>
              <path
                d="M3.56 3.38C5.933 1.129 9.044 0 12.154 0c3.111 0 6.221 1.128 8.595 3.38 2.374 2.255 3.561 5.21 3.561 8.165 0 2.149-.629 4.298-1.886 6.179l6.277 5.96v.003c.517.494.777 1.14.777 1.786 0 .648-.26 1.296-.777 1.788a2.732 2.732 0 01-1.883.739c-.68 0-1.36-.247-1.879-.74h-.004L18.66 21.3a12.62 12.62 0 01-6.506 1.789c-3.11 0-6.221-1.126-8.594-3.382C1.187 17.454 0 14.5 0 11.545 0 8.59 1.187 5.635 3.56 3.38zm9.265-.78a9.773 9.773 0 015.968 2.64c1.555 1.479 2.583 3.462 2.779 5.669a.689.689 0 01-.2.55.755.755 0 01-.56.233h-.031c-.393 0-.727-.287-.759-.661-.164-1.848-1.026-3.51-2.329-4.747a8.2 8.2 0 00-4.998-2.212c-.392-.031-.696-.344-.696-.72v-.03a.69.69 0 01.246-.533.77.77 0 01.58-.188zm8.672 16.33c-.18.205-.368.405-.564.6l-.012.01-.01.01-.01.012-.011.01-.01.01-.012.012-.009.007-.001.002-.011.012-.011.01-.01.01-.077.072-.077.074-.011.01-.01.01-.012.01v.002l-.011.008-.01.01-.012.01-.012.012-.01.01-.012.01-.012.01a11.79 11.79 0 01-.629.536l6.103 5.799c.213.203.499.306.784.306.284 0 .57-.103.784-.306a1.024 1.024 0 00.002-1.491l-.002.002-6.105-5.798zM12.154 1.478c-2.712 0-5.426.983-7.495 2.949-2.07 1.964-3.105 4.542-3.105 7.119s1.035 5.153 3.105 7.119c2.071 1.967 4.783 2.95 7.495 2.95 2.67 0 5.338-.951 7.393-2.854l.005-.005.006-.004.072-.067.01-.01.01-.01.011-.01.01-.008.071-.07.005-.004.005-.005c2.001-1.953 3.002-4.487 3.002-7.022 0-2.577-1.034-5.153-3.104-7.12-2.07-1.965-4.782-2.948-7.496-2.948z"
                fill="#FFF"
                mask="url(#prefix__b)"
              />
            </g>
          </svg>
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <template #icon="props">
          <svg width="38" height="26" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.036 7.85c-1.707 1.54-2.723 3.462-2.723 5.532 0 1.273.382 2.485 1.067 3.585.734 1.173 1.812 2.224 3.149 3.088a.829.829 0 01.35.923l-1.26 4.447 5.522-3.192a.833.833 0 01.588-.093 18.597 18.597 0 003.906.402c.31 0 .617-.01.92-.022a21.93 21.93 0 004.19.366.332.332 0 01.09.654 20.251 20.251 0 01-5.2.668 20.888 20.888 0 01-3.935-.38l-7.003 4.048a.83.83 0 01-1.236-.931l1.657-5.841c-1.311-.93-2.383-2.032-3.15-3.26-.848-1.361-1.32-2.87-1.32-4.462 0-3.044 1.719-5.781 4.5-7.744 1.22-.17.762 1.603-.112 2.213zM21.366 0c4.559 0 8.675 1.295 11.64 3.388 2.867 2.023 4.639 4.789 4.639 7.817 0 1.556-.466 3.04-1.307 4.389-.896 1.435-2.215 2.72-3.851 3.777a.974.974 0 00-.412 1.086l1.56 5.508-6.807-3.936a.983.983 0 00-.694-.112c-.767.164-1.554.288-2.353.37-.772.079-1.58.122-2.415.122-4.563 0-8.678-1.295-11.645-3.39-2.864-2.023-4.638-4.788-4.638-7.814 0-3.028 1.774-5.794 4.638-7.817C12.688 1.295 16.803 0 21.366 0zm8.244 9.073a2.163 2.163 0 00-2.162 2.164 2.164 2.164 0 102.162-2.164zm-7.838 0a2.165 2.165 0 100 4.33 2.165 2.165 0 000-4.33zm-7.838 0a2.165 2.165 0 100 4.33 2.165 2.165 0 000-4.33z"
              fill="#FFF"
              fill-rule="evenodd"
            />
          </svg>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/user">
        <template #icon="props">
          <svg width="24" height="28" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.869 0c1.905 0 3.629.772 4.877 2.02a6.88 6.88 0 012.02 4.878 6.901 6.901 0 01-3.982 6.252 11.41 11.41 0 015.11 2.957 11.329 11.329 0 013.338 8.026v1.363c0 .69-.282 1.317-.735 1.769a2.492 2.492 0 01-1.768.735H3.008a2.494 2.494 0 01-1.768-.735 2.49 2.49 0 01-.735-1.769v-1.363c0-3.126 1.278-5.967 3.337-8.026a11.416 11.416 0 015.11-2.957 6.93 6.93 0 01-1.961-1.374 6.88 6.88 0 01-2.02-4.878A6.88 6.88 0 016.99 2.02 6.88 6.88 0 0111.869 0"
              fill="#FFF"
              fill-rule="evenodd"
            />
          </svg>
        </template>
      </van-tabbar-item>
    </div>
  </van-tabbar>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {
    onChange(index) {
      if (index === 2) {
        console.log("index", index);
      }
    },
  },
  components: {},
};
</script>

