<template>
  <header class="fixed left-0 right-0 top-0 bg-black bg-opacity-50 py-[10px] flex justify-center z-30">
    <router-link to="/home">
      <img src="../../assets/logo.svg" alt="" class="h-10">
    </router-link>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {}
  },
  components: {},
}
</script>
