<template>
  <div class="">
    <Header v-show="!$route.meta.hasHeader" />
    <div class="max-w-2xl mx-auto">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/layout/components/Header.vue'
import Footer from '@/layout/components/Footer.vue'

export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
  },
  computed: {
    key() {
      return this.$route.path
    },
  },
}
</script>
