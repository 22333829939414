<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
export default {
  data() {
    return {
      primary: "#D02C27",
      secondary: "#222130",
    };
  },
  created() {
    this.getSiteData();
  },
  methods: {
    // 获取网站信息
    getSiteData() {
      const hexToRgb = (hex) =>
        hex
          .replace(
            /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
            (m, r, g, b) => "#" + r + r + g + g + b + b
          )
          .substring(1)
          .match(/.{2}/g)
          .map((x) => parseInt(x, 16));
      document
        .querySelector(":root")
        .style.setProperty("--color-primary", hexToRgb(this.primary));
      document
        .querySelector(":root")
        .style.setProperty("--color-secondary", hexToRgb(this.secondary));
    },
  },
};
</script>
