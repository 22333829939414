import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/mall",
        name: "mall",
        component: () => import("../views/Mall.vue"),
      },
      {
        path: "/mall-detail",
        name: "mall-detail",
        component: () => import("../views/Mall-detail.vue"),
      },
      {
        path: "/hotel",
        name: "Hotel",
        component: () => import("../views/Hotel.vue"),
      },
      {
        path: "/hotel-detail",
        name: "Hotel-detail",
        component: () => import("../views/Hotel-detail.vue"),
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/Login.vue"),
        meta: { isPublic: true, hasHeader: false, title: "login.title" },
      },
      {
        path: "/user",
        name: "User",
        component: () => import("../views/User.vue"),
        meta: { hasHeader: true },
      },
      {
        path: "/result",
        name: "Result",
        component: () => import("../views/Result.vue"),
        meta: { hasHeader: true, hasHeader: false },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;



