<template>
  <div class="relative">
    <swiper
      class="index-swiper"
      ref="indexSwiper"
      :options="indexSwiperOptions"
    >
      <swiper-slide
        ><a href="" class="relative">
          <svg
            class="
              absolute
              top-1/2
              left-1/2
              transform
              -translate-x-1/2 -translate-y-1/2
            "
            width="64"
            height="64"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fill-rule="evenodd">
              <path
                d="M21.455 48.2V15.797h.003a.896.896 0 011.377-.756l25.17 16.085a.884.884 0 01.305.288.9.9 0 01-.264 1.242l-25.144 16.26a.899.899 0 01-1.447-.716"
                fill="#FFF"
              />
              <path
                d="M62 32c0 16.569-13.431 30-30 30C15.431 62 2 48.569 2 32 2 15.431 15.431 2 32 2c16.569 0 30 13.431 30 30z"
                stroke="#FFF"
                stroke-width="4"
              />
            </g>
          </svg>
          <img
            src="../assets/pic/pic13.jpg"
            class="w-full h-screen object-cover"
            alt="" /></a
      ></swiper-slide>
      <swiper-slide
        ><a href="" class="relative">
          <svg
            class="
              absolute
              top-1/2
              left-1/2
              transform
              -translate-x-1/2 -translate-y-1/2
            "
            width="64"
            height="64"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fill-rule="evenodd">
              <path
                d="M21.455 48.2V15.797h.003a.896.896 0 011.377-.756l25.17 16.085a.884.884 0 01.305.288.9.9 0 01-.264 1.242l-25.144 16.26a.899.899 0 01-1.447-.716"
                fill="#FFF"
              />
              <path
                d="M62 32c0 16.569-13.431 30-30 30C15.431 62 2 48.569 2 32 2 15.431 15.431 2 32 2c16.569 0 30 13.431 30 30z"
                stroke="#FFF"
                stroke-width="4"
              />
            </g>
          </svg>
          <img
            src="../assets/pic/pic13.jpg"
            class="w-full h-screen object-cover"
            alt="" /></a
      ></swiper-slide>
      <swiper-slide
        ><a href="" class="relative">
          <svg
            class="
              absolute
              top-1/2
              left-1/2
              transform
              -translate-x-1/2 -translate-y-1/2
            "
            width="64"
            height="64"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fill-rule="evenodd">
              <path
                d="M21.455 48.2V15.797h.003a.896.896 0 011.377-.756l25.17 16.085a.884.884 0 01.305.288.9.9 0 01-.264 1.242l-25.144 16.26a.899.899 0 01-1.447-.716"
                fill="#FFF"
              />
              <path
                d="M62 32c0 16.569-13.431 30-30 30C15.431 62 2 48.569 2 32 2 15.431 15.431 2 32 2c16.569 0 30 13.431 30 30z"
                stroke="#FFF"
                stroke-width="4"
              />
            </g>
          </svg>
          <img
            src="../assets/pic/pic13.jpg"
            class="w-full h-screen object-cover"
            alt="" /></a
      ></swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>

    <div class="absolute w-full bottom-28 text-center z-30">
      <a
        class="
          inline-block
          text-xl text-white
          px-5
          py-2
          rounded-full
          bg-primary
        "
        @click="toggleSearch"
        >BOOK NOW</a
      >
    </div>
    <van-popup class="popup-box" v-model="searchShow">
      <div class="overflow-hidden rounded-3xl bg-white">
        <div
          class="
            h-10
            flex
            items-center
            justify-center
            relative
            bg-secondary
            text-white text-center
          "
        >
          <div>ACCOMMODATION SEARCH</div>
          <a class="absolute right-3" @click="toggleSearch"
            ><svg width="20" height="19" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path
                  d="M10 18.33c-4.971 0-9-3.879-9-8.665S5.029 1 10 1s9 3.879 9 8.665-4.029 8.665-9 8.665Z"
                  stroke="#FFF"
                />
                <path
                  d="M6.823 6 9.35 9.65 6.7 13.457h1.494l1.079-1.68c.277-.446.539-.847.785-1.293h.03c.263.446.51.862.802 1.294l1.094 1.679h1.54l-2.619-3.851L13.447 6h-1.463l-1.048 1.587c-.246.4-.493.785-.74 1.232h-.045a26.112 26.112 0 0 0-.755-1.217L8.333 6h-1.51Z"
                  fill="#FFF"
                  fill-rule="nonzero"
                />
              </g></svg
          ></a>
        </div>
        <van-form @submit="searchSubmit">
          <div class="px-5 py-4 text-center">
            <div class="relative rounded-full overflow-hidden mb-5">
              <div
                class="absolute left-6 top-0 bottom-0 flex items-center z-10"
              >
                <img src="../assets/images/icon-map.svg" class="h-5" alt="" />
              </div>
              <van-field
                placeholder="Destination"
                input-align="center"
                v-model="destination"
                :rules="[{ message: 'Destination' }]"
              />
            </div>
            <div class="relative rounded-full overflow-hidden mb-5">
              <div
                class="absolute left-6 top-0 bottom-0 flex items-center z-10"
              >
                <img
                  src="../assets/images/icon-checkin.svg"
                  class="h-5"
                  alt=""
                />
              </div>
              <van-field
                placeholder="Check-in date"
                input-align="center"
                v-model="checkin"
                :rules="[{ message: 'Check-in date' }]"
              />
            </div>
            <div class="relative rounded-full overflow-hidden mb-5">
              <div
                class="absolute left-6 top-0 bottom-0 flex items-center z-10"
              >
                <img
                  src="../assets/images/icon-checkout.svg"
                  class="h-5"
                  alt=""
                />
              </div>
              <van-field
                placeholder="Check-out date"
                input-align="center"
                v-model="checkout"
                :rules="[{ message: 'Check-out date' }]"
              />
            </div>
            <div class="relative rounded-full overflow-hidden mb-5">
              <div
                class="absolute left-6 top-0 bottom-0 flex items-center z-10"
              >
                <img
                  src="../assets/images/icon-user-black.svg"
                  class="h-5"
                  alt=""
                />
              </div>
              <van-field
                placeholder="Number of guest"
                input-align="center"
                v-model="guest"
                :rules="[{ message: 'Number of guest' }]"
              />
            </div>
          </div>
          <div
            class="h-10 leading-10 text-center bg-primary text-white uppercase"
          >
            <button class="block text-center w-full" type="submit">
              Search
            </button>
          </div>
        </van-form>
      </div>
    </van-popup>
    <van-popup class="popup-box" v-model="resShow">
      <div class="overflow-hidden rounded-3xl bg-white">
        <div
          class="
            h-10
            flex
            items-center
            justify-center
            relative
            bg-secondary
            text-white text-center
          "
        >
          <div>RESERVATION</div>
          <a class="absolute right-3" @click="toggleRes"
            ><svg width="20" height="19" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path
                  d="M10 18.33c-4.971 0-9-3.879-9-8.665S5.029 1 10 1s9 3.879 9 8.665-4.029 8.665-9 8.665Z"
                  stroke="#FFF"
                />
                <path
                  d="M6.823 6 9.35 9.65 6.7 13.457h1.494l1.079-1.68c.277-.446.539-.847.785-1.293h.03c.263.446.51.862.802 1.294l1.094 1.679h1.54l-2.619-3.851L13.447 6h-1.463l-1.048 1.587c-.246.4-.493.785-.74 1.232h-.045a26.112 26.112 0 0 0-.755-1.217L8.333 6h-1.51Z"
                  fill="#FFF"
                  fill-rule="nonzero"
                />
              </g></svg
          ></a>
        </div>
        <van-form @submit="reSubmit">
          <div class="px-5 py-4 text-center">
            <div class="relative rounded-full overflow-hidden mb-5">
              <div
                class="absolute left-6 top-0 bottom-0 flex items-center z-10"
              >
                <img
                  src="../assets/images/icon-user-black.svg"
                  class="h-5"
                  alt=""
                />
              </div>
              <van-field
                placeholder="Number of product/guest"
                input-align="center"
                v-model="guest"
                :rules="[{ message: 'Number of product/guest' }]"
              />
            </div>
            <div class="relative rounded-full overflow-hidden mb-5">
              <div
                class="absolute left-6 top-0 bottom-0 flex items-center z-10"
              >
                <img
                  src="../assets/images/icon-calendar.svg"
                  class="h-5"
                  alt=""
                />
              </div>
              <van-field
                placeholder="Reservation Date"
                input-align="center"
                v-model="checkin"
                :rules="[{ message: 'Reservation Date' }]"
              />
            </div>
            <div class="relative rounded-full overflow-hidden mb-5">
              <div
                class="absolute left-6 top-0 bottom-0 flex items-center z-10"
              >
                <img src="../assets/images/icon-clock.svg" class="h-5" alt="" />
              </div>
              <van-field
                placeholder="Reservation Time"
                input-align="center"
                v-model="checkout"
                :rules="[{ message: 'Reservation Time' }]"
              />
            </div>
            <dl class="grid grid-cols-2 text-left gap-3 mb-3">
              <dt class="text-right font-bold">Option :</dt>
              <dd></dd>
            </dl>
            <dl class="grid grid-cols-2 text-left gap-3 mb-3">
              <dt class="text-right">Lift ticket (JP10000)</dt>
              <dd>
                <van-stepper
                  v-model="stepper01"
                  theme="round"
                  disable-input
                  min="0"
                />
              </dd>
            </dl>
            <dl class="grid grid-cols-2 text-left gap-3 mb-3">
              <dt class="text-right">Gear (JP10000)</dt>
              <dd>
                <van-stepper
                  v-model="stepper02"
                  theme="round"
                  disable-input
                  min="0"
                />
              </dd>
            </dl>
          </div>
          <div
            class="h-10 leading-10 text-center bg-primary text-white uppercase"
          >
            <button class="block text-center w-full" type="submit">
              <div class="flex items-center justify-center gap-2">
                NEXT <i class="icon-next h-3 w-3"></i>
              </div>
            </button>
          </div>
        </van-form>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      searchShow: false,
      resShow: false,
      guest: null,
      checkin: null,
      checkout: null,
      destination: null,
      stepper01: 0,
      stepper02: 0,
      indexSwiperOptions: {
        loop: true,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false,
        // },
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    toggleSearch() {
      if (this.searchShow) {
        this.searchShow = false;
      } else {
        this.searchShow = true;
      }
    },
    toggleRes() {
      if (this.resShow) {
        this.resShow = false;
      } else {
        this.resShow = true;
      }
    },
    searchSubmit() {
      console.log("searchSubmit");
      this.searchShow = false;
      this.resShow = true;
    },
    reSubmit() {
      this.resShow = false;
    },
  },
};
</script>
